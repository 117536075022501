































































































































import { Component, Vue, Watch } from 'vue-property-decorator'

import {
  namespace as participantStoreName,
  actionNames,
  mutationNames,
  getterNames,
  LoadProgramParticipantsArgs,
} from '@/store/participants'
import { namespace as leagueStoreName } from '@/store/leagues'
import { leagueAbstractionNamespace } from '@/store/leagueAbstraction'
import { namespace } from 'vuex-class'
import { namespace as gradeStoreName } from '@/store/gradeTypes'
import * as gradeStore from '@/store/gradeTypes'

const participants = namespace(participantStoreName)
const league = namespace(leagueStoreName)
const leagueAbstraction = namespace(leagueAbstractionNamespace)
const gradeTypes = namespace(gradeStoreName)

/**types */
import { LeaguePlayerInfo } from '@/GeneratedTypes/ListInfo/LeaguePlayerInfo'
import { League } from '@/GeneratedTypes/League'
import { LeagueProgram } from '@/GeneratedTypes/LeagueProgram'
import { PlayerInfoIDType } from '@/lib/support/models/LeaguePlayerInfo/data'
import { LeaguePlayer } from '@/GeneratedTypes/LeaguePlayer'

/** external data */
import {
  leagueColumnHeaders,
  campColumnHeaders,
  leagueColumnHeadersByAge,
  campColumnHeadersByAge,
} from './ext/batchParticipantHeaders'

/**components */
import Loading from '@/elements/Loading.vue'
import FullBody from '@/components/FullBody.vue'
import TextInput from '@/elements/TextInput.vue'
import HorizontalTabs from '@/components/HorizontalNavTabs.vue'
import DataTable from '@/elements/DataTable/DataTable.vue'
import { DataTableSelection } from '@/models/DataTable/DataTableSelection'
import PlayerEvaluationEditGrid from '@/components/PlayerEvaluationEditGrid.vue'
import UDFPanel from '@/components/UDFPanel.vue'

/** local components */
import TypeProgramTab from '@/components/TypeProgramTab.vue'
import { LeagueProgams2LeagueListItem } from '@/lib/support/models/LeagueListItem/LeaguePrograms2LeagueListItem'
import {
  LeagueInfoMoreThanOneProgram,
  LeagueListItemSeed,
  getEmptyLeagueListItem,
} from '@/lib/support/models/LeagueListItem/data'

import { LeagueListItem } from '@/models/Program/LeagueListItem'
import { PlayerId } from '@/lib/support/models/LeaguePlayer/data'
import teamsClient from '@/clients/teamsClient'
import { DivisionTeamInfo } from '@/GeneratedTypes/ListInfo/DivisionTeamInfo'
import { PlayerProgram } from '@/GeneratedTypes/PlayerProgram'
import { getEmptyPlayerProgram } from '@/lib/support/models/PlayerProgram/data'
import { PlayerEvaluation } from '@/GeneratedTypes/PlayerEvaluation'
import participantsClient from '@/clients/participantsClient'
import { UDFValue } from '@/lib/support/components/UDFPanel/UDFValue'
import { PlayerProduct } from '@/GeneratedTypes/PlayerProduct'
import { ParticipantOrderProductInfo } from '@/GeneratedTypes/ListInfo/ParticipantOrderProductInfo'
import { UpwardGradeTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardGradeTypeID'
import SizeSelectorPanel from '@/components/SizeSelectorPanel/SizeSelectorPanel.vue'
import ordersClient from '@/clients/ordersClient'
import { productListToProductSelections } from '@/lib/support/components/SizeSelectorPanel/operations'
import { Identity } from '@/lib/support/components/SizeSelectorPanel/operations'
import { UpwardOpportunityTypes } from '@/lib/common/upwardOpportunityTypes'
import DropDownMultiSelect from '@/components/DropDownMultiSelect.vue'
import { MultiSelectValue } from '@/lib/support/components/MultiSelect/MultiSelectValue'
import uuid from 'uuid'
import { getProperGradeLabel } from '@/services/gradeService'

@Component({
  components: {
    FullBody,
    DataTable,
    HorizontalTabs,
    TextInput,
    TypeProgramTab,
    PlayerEvaluationEditGrid,
    Loading,
    UDFPanel,
    SizeSelectorPanel,
    DropDownMultiSelect,
  },
  methods: {
    LeagueInfoMoreThanOneProgram,
    getProperGradeLabel,
  },
})
export default class ParticipantsBatchEdit extends Vue {
  saving = false
  searchTerm = ''
  selectedParticipant: LeaguePlayer | null = null
  playerUdfs: UDFValue[] | null = null

  @participants.Mutation(mutationNames.setCurrentProgram) setCurrentStoreProgram!: ({
    program,
  }: {
    program: string
  }) => void

  @participants.Mutation(mutationNames.updateParticipantData) updateParticipantData!: ({
    playerInfo,
  }: {
    playerInfo: LeaguePlayerInfo
  }) => void

  /** actions */
  @participants.Action(actionNames.setCurrentParticipantByID) setCurrentParticipantByID!: ({
    id,
    typeProgramID,
  }: {
    id: PlayerInfoIDType
    typeProgramID: string
  }) => Promise<void>

  @participants.Action(actionNames.loadProgramParticipants) loadProgramParticipants!: (
    p: LoadProgramParticipantsArgs
  ) => void

  /** getters */
  @league.Getter('currentItem')
  league!: League

  @leagueAbstraction.Getter('currentItem') leagueAbstraction!: League
  @leagueAbstraction.Getter('programType') programType!: number
  @leagueAbstraction.Getter('isUpwardSelect') isUpwardSelect!: boolean
  @leagueAbstraction.Getter('isByAge') isByAge!: boolean

  @participants.Getter('participants')
  participants!: LeaguePlayerInfo[]

  currentParticipant: LeaguePlayerInfo | null = null

  @participants.Getter('currentProgram')
  currentStoreProgram!: string

  @participants.Getter(getterNames.participantUnderEdit)
  readonly participantTemplate!: LeaguePlayer

  @gradeTypes.Getter('byUpwardTypeId')
  getUpwardGradeTypeID!: (typeID: string) => UpwardGradeTypeID | null

  programChoice = 0
  get currentPlayerProgram(): PlayerProgram {
    if (this.participantTemplate?.programs?.[this.programChoice]) {
      return this.participantTemplate?.programs?.[this.programChoice]
    }
    return getEmptyPlayerProgram() //programs not loaded, or invalid program.
  }

  private teamInfo: DivisionTeamInfo[] = []
  private editEvaluations: PlayerEvaluation[] = []
  private productItems: ParticipantOrderProductInfo[] = []
  private editProducts: PlayerProduct[] = []
  private selectedGrades: MultiSelectValue[] = []
  private selectedGenders: MultiSelectValue[] = []
  private gradesKey: string = uuid.v4()
  private gendersKey: string = uuid.v4()

  private get UpwardLeagueId(): string {
    return this.$route.params.id ?? ''
  }

  get columnHeaders() {
    if (this.isByAge) {
      if (!this.isLeague) {
        return campColumnHeadersByAge
      }
      return leagueColumnHeadersByAge
    } else {
      if (!this.isLeague) {
        return campColumnHeaders
      }
      return leagueColumnHeaders
    }
  }

  get isLeague() {
    return this.programType == UpwardOpportunityTypes.LEAGUE
  }

  /**
   * Determines if products are shown
   */
  private get showingProducts() {
    return (
      this.productItems?.length &&
      this.editProducts?.length &&
      productListToProductSelections(
        this.editProducts,
        this.productItems,
        this.currentParticipant?.gender ?? '',
        Identity.PARTICIPANT
      ).length
    )
  }

  /***
   * Visible participants are influenced by the current tab
   */
  get visibleParticipants() {
    const participants = this.participants.map((p) => {
      return { ...p, name: `${p.lastName}, ${p.firstName}` }
    })

    const program = this.programByIndex(this.currentProgram)
    if (program && this.isLeague) {
      return participants.filter((x) => program.typeProgramID == x.typeProgramID)
    }
    return participants
  }

  get filtersParticipants() {
    return this.visibleParticipants
      .filter(
        (x) =>
          x.firstName &&
          x.lastName &&
          `${x.firstName} ${x.lastName!}`.toLocaleLowerCase().search(this.searchTerm.toLocaleLowerCase()) !=
            -1
      )
      .filter(
        (x) =>
          this.selectedGrades.length == 0 || this.selectedGrades.map((g) => g.id).includes(x.typeGradeID!)
      )
      .filter(
        (x) =>
          this.selectedGenders.length == 0 ||
          this.selectedGenders.map((g) => g.id.charAt(0)).includes(x.gender!)
      )
      .map((x) => {
        return { ...x, ageByCutoff: this.getUpwardGradeTypeID(x.typeGradeID!)?.ageByCutoff }
      })
  }

  get visibleGrades() {
    const d = [...new Set(this.visibleParticipants.map((x) => x.typeGradeID))]
    return gradeStore.gradeTypes.state.items.filter((g) => d.indexOf(g.upwardTypeID) >= 0)
  }

  get loading(): boolean {
    return !this.participants || this.participants.length === 0
  }

  /***
   * Returns the structure to define the tabs based on loaded league
   */
  /** index into this.leagues.programs[] variables  */
  get currentProgram(): string {
    return this.currentStoreProgram
  }

  set currentProgram(p: string) {
    this.setCurrentStoreProgram({ program: p })
  }

  get tabs(): LeagueListItem {
    if (this.leagueAbstraction && this.leagueAbstraction.programs) {
      this.currentProgram = this.leagueAbstraction.programs[0].typeProgramID
      return LeagueProgams2LeagueListItem(this.leagueAbstraction.programs)
    }
    return getEmptyLeagueListItem(new LeagueListItemSeed())
  }

  get selectedPlayerProgram(): PlayerProgram | null {
    if (!this.currentProgram || !this.selectedParticipant || !this.selectedParticipant.programs) {
      return null
    }
    const playerProgram = this.selectedParticipant.programs.find(
      (p) => p.typeProgramID === this.currentProgram && p.active
    )
    if (!playerProgram) {
      return null
    }
    return playerProgram
  }

  /**
   * Returns the order list of things a participant needs to order
   */
  async getProducts() {
    this.productItems = (await ordersClient.getLeagueOrderItems(this.UpwardLeagueId)) ?? []
  }

  async setLocalCurrentParticipantID(id: PlayerInfoIDType) {
    this.saving = true
    await this.setCurrentParticipantByID({ id: id, typeProgramID: this.currentProgram })
    this.teamInfo = await teamsClient.retrieveTeamInfo({
      leagueID: this.UpwardLeagueId,
      individualId: id,
    })

    this.selectedParticipant = await participantsClient.retrieveParticipant(this.UpwardLeagueId, id)

    if (this.selectedParticipant) {
      this.playerUdfs = this.selectedParticipant.udFs
    }

    this.saving = false
  }

  rowSelected(x: DataTableSelection<LeaguePlayerInfo>) {
    this.currentParticipant = x.item
    const id = PlayerId(x.item)
    if (id) {
      this.setLocalCurrentParticipantID(id)
    }
  }
  programByIndex(index: string): LeagueProgram | null {
    if (this?.league?.programs) {
      let id = this.league.programs.findIndex((x) => x.typeProgramID == index)
      if (id < 0) {
        id = 0
      }
      return this?.league?.programs![id] ?? null
    }
    return null
  }

  formerParticipantSearchSelected(pid: PlayerInfoIDType) {
    alert('selected former participant' + pid)
  }

  async saveParticipant() {
    if (!this.selectedPlayerProgram || !this.selectedParticipant) {
      return
    }

    this.saving = true
    this.selectedParticipant.udFs = this.playerUdfs
    await participantsClient.save(this.UpwardLeagueId, this.selectedParticipant)

    const playerInfo = await participantsClient.retrieveParticipantInfo(
      this.UpwardLeagueId,
      this.selectedParticipant.individualID
    )

    if (playerInfo) {
      this.updateParticipantData({ playerInfo: playerInfo })
    }
    this.currentParticipant = null
    this.selectedParticipant = null
    this.playerUdfs = []
    this.saving = false
  }

  cancel() {
    this.currentParticipant = null
    this.selectedParticipant = null
    this.playerUdfs = []
  }

  private async mounted() {
    await this.getProducts()
  }
  @Watch('currentPlayerProgram.products', { immediate: true })
  private onProductsChange() {
    this.editProducts = [...(this.currentPlayerProgram.products || [])]
  }
  @Watch('currentProgram', { immediate: true })
  private onProgramChange() {
    this.gradesKey = uuid.v4()
    this.gendersKey = uuid.v4()
    this.selectedGrades = []
    this.selectedGenders = []
    this.searchTerm = ''
  }
}

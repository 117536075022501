import { DataTableHeader } from 'vuetify/types'

export const leagueColumnHeaders: DataTableHeader[] = [
  { text: 'Name', value: 'name' },
  { text: 'Gender', value: 'gender' },
  { text: 'Grade', value: 'typeGradeID' },
  { text: 'Score', value: 'evaluationScore' },
]

export const campColumnHeaders: DataTableHeader[] = [
  { text: 'Name', value: 'name' },
  { text: 'Gender', value: 'gender' },
  { text: 'Grade', value: 'typeGradeID' },
]

export const leagueColumnHeadersByAge: DataTableHeader[] = [
  { text: 'Name', value: 'name' },
  { text: 'Gender', value: 'gender' },
  { text: 'Age', value: 'ageByCutoff' },
  { text: 'Score', value: 'evaluationScore' },
]

export const campColumnHeadersByAge: DataTableHeader[] = [
  { text: 'Name', value: 'name' },
  { text: 'Gender', value: 'gender' },
  { text: 'Age', value: 'ageByCutoff' },
]

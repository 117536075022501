import { PlayerProgram } from '@/GeneratedTypes/PlayerProgram'

export function getEmptyPlayerProgram(): PlayerProgram {
  return {
    typeProgramID: '',
    evaluations: [],
    adminNotes: '',
    carpoolLink: null,
    coachLinkIndividualID: 0,
    payments: [],
    pendingCoachLinkFirstName: '',
    pendingCoachLinkIndividualID: 0,
    pendingCoachLinkLastName: '',
    products: [],
    yearsExperience: 0,
    active: true,
    jerseyNumber: '',
    doNotDraft: false,
  }
}
